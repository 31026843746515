import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "inicio",
    component: () => import("@/layout/WebLayout.vue"),
    children: [
      {
        path: "/",
        name: "Inicio",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/equipo-dirbde",
        name: "Equipo DIRBDE",
        component: () => import("@/views/EquipoDirbde.vue"),
      },
      {
        path: "/noticias",
        name: "Noticias",
        component: () => import("@/views/News.vue"),
      },
      {
        path: "/aplicativos",
        name: "Herramientas",
        component: () => import("@/views/Aplicativos.vue"),
      },
      {
        path: "/noticias/:slug",
        name: "Detalle de noticia",
        component: () => import("@/views/NewsDetail.vue"),
        props: true,
      },
      {
        path: "/newsletters",
        name: "Publicaciones",
        component: () => import("@/views/Newsletters.vue"),
        props: true,
      },
      {
        path: "/oauth2",
        name: "Autentificacion",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/OAuth2.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/dashboard",
    redirect: "/dashboard",
    name: "dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/DashboardGral.vue"),
      },
      {
        path: "/post/:id",
        name: "Detalle de documento",
        component: () =>
          import("@/views/crafted/pages/intranet/PostDetail.vue"),
      },
      {
        path: "/beneficios-internos/arancel/buch",
        name: "Arancel - BUCH",
        component: () => import("@/views/crafted/pages/intranet/Buch.vue"),
      },
      {
        path: "/beneficios-internos/arancel/bab",
        name: "Arancel - BAB",
        component: () => import("@/views/crafted/pages/intranet/Bab.vue"),
      },
      {
        path: "/beneficios-internos/mantencion/bae",
        name: "Mantención - BAE",
        component: () => import("@/views/crafted/pages/intranet/Bae.vue"),
      },
      {
        path: "/beneficios-internos/mantencion/mbrunet",
        name: "Mantención - M. Brunet",
        component: () => import("@/views/crafted/pages/intranet/Mbrunet.vue"),
      },
      {
        path: "/beneficios-internos/mantencion/bal",
        name: "Mantención - BAL",
        component: () => import("@/views/crafted/pages/intranet/Bal.vue"),
      },
      {
        path: "/beneficios-internos/mantencion/per",
        name: "Mantención - PER",
        component: () => import("@/views/crafted/pages/intranet/Per.vue"),
      },
      {
        path: "/beneficios-internos/mantencion/beca-emergencia",
        name: "Mantención - Beca Emergencia",
        component: () =>
          import("@/views/crafted/pages/intranet/BecaEmergencia.vue"),
      },
      {
        path: "/beneficios-internos/mantencion/beca-irma-salas",
        name: "Mantención - Beca Irma Salas",
        component: () =>
          import("@/views/crafted/pages/intranet/BecaIrmaSalas.vue"),
      },
      {
        path: "/beneficios-internos/mantencion/mantencion-documentos-generales",
        name: "Mantención - Documentos Generales",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/MantencionDocumentosGenerales.vue"
          ),
      },
      {
        path: "/beneficios-internos/programa-corresponsabilidad-parental/bapr",
        name: "P. Corresponsabilidad Parental - Beca de Apoyo Preescolar",
        component: () => import("@/views/crafted/pages/intranet/Bapr.vue"),
      },
      {
        path: "/beneficios-internos/programa-corresponsabilidad-parental/bapa",
        name: "P. Corresponsabilidad Parental - Beca de Apoyo Parental",
        component: () => import("@/views/crafted/pages/intranet/Bapa.vue"),
      },
      {
        path: "/beneficios-internos/programa-corresponsabilidad-parental/pcp-documentos-generales",
        name: "P. Corresponsabilidad Parental - Documentos Generales",
        component: () =>
          import("@/views/crafted/pages/intranet/PCPDocumentosGenerales.vue"),
      },
      {
        path: "/beneficios-internos/programa-residencia-universitaria/beri",
        name: "Programa de Residencia Universitaria - Beca de Residencia Interna",
        component: () => import("@/views/crafted/pages/intranet/Beri.vue"),
      },
      {
        path: "/beneficios-internos/programa-residencia-universitaria/pru-documentos-generales",
        name: "Programa de Residencia Universitaria - Documentos Generales",
        component: () =>
          import("@/views/crafted/pages/intranet/PRUDocumentosGenerales.vue"),
      },
      {
        path: "/beneficios-internos/programa-residencia-universitaria/vru",
        name: "Programa de Residencia Universitaria - Vacante de Residencia Universitaria",
        component: () => import("@/views/crafted/pages/intranet/Vru.vue"),
      },
      {
        path: "/beneficios-internos/admision-especial/sipee",
        name: "Administion Especial - SIPEE",
        component: () => import("@/views/crafted/pages/intranet/Sipee.vue"),
      },
      {
        path: "/beneficios-internos/admision-especial/pace",
        name: "Administion Especial - PACE",
        component: () => import("@/views/crafted/pages/intranet/Pace.vue"),
      },
      {
        path: "/beneficios-externos/mineduc/postulacion-y-acreditacion",
        name: "Mineduc - Postulación y Acreditación",
        component: () =>
          import("@/views/crafted/pages/intranet/PostYAcred.vue"),
      },
      {
        path: "/beneficios-externos/mineduc/nuevas-asignaciones",
        name: "Mineduc - Nuevas Asignaciones",
        component: () =>
          import("@/views/crafted/pages/intranet/NuevasAsignaciones.vue"),
      },
      {
        path: "/beneficios-externos/mineduc/renovacion-beneficios",
        name: "Mineduc - Renovación Beneficios",
        component: () =>
          import("@/views/crafted/pages/intranet/RenovacionBeneficios.vue"),
      },
      {
        path: "/beneficios-externos/mineduc/mineduc-documentos-generales",
        name: "Mineduc - Documentos Generales",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/MineducDocumentosGenerales.vue"
          ),
      },
      {
        path: "/beneficios-externos/ingresa/licitaciones",
        name: "Ingresa - Licitaciones",
        component: () =>
          import("@/views/crafted/pages/intranet/Licitaciones.vue"),
      },
      {
        path: "/beneficios-externos/ingresa/renovantes",
        name: "Ingresa - Renovantes",
        component: () =>
          import("@/views/crafted/pages/intranet/Renovantes.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/baes",
        name: "Junaeb - BAES",
        component: () => import("@/views/crafted/pages/intranet/Baes.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/bpr",
        name: "Junaeb - Beca Presidente de la República",
        component: () => import("@/views/crafted/pages/intranet/Bpr.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/bi",
        name: "Junaeb - Beca Indígena",
        component: () => import("@/views/crafted/pages/intranet/Bi.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/breg",
        name: "Junaeb - Becas Regionales",
        component: () => import("@/views/crafted/pages/intranet/Breg.vue"),
      },
      /*
      {
        path: "/beneficios-externos/junaeb/bri",
        name: "bri",
        component: () => import("@/views/crafted/pages/intranet/Bri.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/bit",
        name: "bit",
        component: () => import("@/views/crafted/pages/intranet/Bit.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/pa",
        name: "pa",
        component: () => import("@/views/crafted/pages/intranet/Pa.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/polimetales",
        name: "polimetales",
        component: () =>
        import("@/views/crafted/pages/intranet/Polimetales.vue"),
      },
      */
      {
        path: "/beneficios-externos/junaeb/bmvp",
        name: "Junaeb - Beca Melipilla Vive la U",
        component: () => import("@/views/crafted/pages/intranet/Bmvp.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/bemes",
        name: "Junaeb - BMES",
        component: () => import("@/views/crafted/pages/intranet/Bemes.vue"),
      },
      {
        path: "/beneficios-externos/junaeb/junaeb-documentos-generales",
        name: "Junaeb - Documentos Generales",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/JunaebDocumentosGenerales.vue"
          ),
      },
      {
        path: "/beneficios-externos/tne",
        name: "TNE",
        component: () => import("@/views/crafted/pages/intranet/Tne.vue"),
      },
      {
        path: "/la-uinvita/actividades",
        name: "La U Invita - Actividades",
        component: () =>
          import("@/views/crafted/pages/intranet/Actividades.vue"),
      },
      {
        path: "/la-uinvita/participantes",
        name: "La U Invita - Participantes",
        component: () =>
          import("@/views/crafted/pages/intranet/Participantes.vue"),
      },
      {
        path: "/la-uinvita/sistematizacion/encuestas",
        name: "La U Invita - Encuestas",
        component: () => import("@/views/crafted/pages/intranet/Encuestas.vue"),
      },
      {
        path: "/la-uinvita/sistematizacion/informes",
        name: "La U Invita - Sistematización - Informes",
        component: () => import("@/views/crafted/pages/intranet/Informes.vue"),
      },
      {
        path: "/la-uinvita/convenios",
        name: "La U Invita - Convenios",
        component: () => import("@/views/crafted/pages/intranet/Convenios.vue"),
      },
      {
        path: "/proyectos-estudiantiles/praz",
        name: "Proyectos Estudiantiles - Premio Azul",
        component: () => import("@/views/crafted/pages/intranet/Praz.vue"),
      },
      {
        path: "/proyectos-estudiantiles/prazco",
        name: "Proyectos Estudiantiles - Premio Azul Comunidad",
        component: () => import("@/views/crafted/pages/intranet/Prazco.vue"),
      },
      {
        path: "/proyectos-estudiantiles/fdi",
        name: "Proyectos Estudiantiles - FDI",
        component: () => import("@/views/crafted/pages/intranet/Fdi.vue"),
      },
      {
        path: "/territorio-uchile/desarrollo-ciudadano",
        name: "Territorio UChile - Desarrollo Ciudadano",
        component: () =>
          import("@/views/crafted/pages/intranet/DesarrolloCiudadano.vue"),
      },
      {
        path: "/territorio-uchile/pasantias",
        name: "Territorio UChile - Pasantías",
        component: () => import("@/views/crafted/pages/intranet/Pasantias.vue"),
      },
      {
        path: "/convivencia-universitaria/pdi-informes",
        name: "Convivencia Universitaria - Informes",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/programa-dialogos-intergeneracionales/Informes.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/pdi-videos",
        name: "Convivencia Universitaria - Videos",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/programa-dialogos-intergeneracionales/Videos.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/pdi-notas-prensa",
        name: "Convivencia Universitaria - Notas de Prensa",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/programa-dialogos-intergeneracionales/Notas-Prensa.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/pdi-fotos",
        name: "Convivencia Universitaria - Fotos",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/programa-dialogos-intergeneracionales/Fotos.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/pdi-intervenciones",
        name: "Convivencia Universitaria - Programa de Diálogos Intergeneracionales - Intervenciones",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/programa-dialogos-intergeneracionales/Intervenciones.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/iic-documentos-investigacion",
        name: "Convivencia Universitaria - Documentos de Investigación",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/investigacion-intervencion-convivencia/Documentos-Investigacion.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/iic-presentacion-seminarios",
        name: "Convivencia Universitaria - Presentación Seminarios",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/investigacion-intervencion-convivencia/Presentacion-Seminarios.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/iic-noticias",
        name: "Convivencia Universitaria - Noticias",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/investigacion-intervencion-convivencia/Noticias.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/iic-seminarios-convivencia",
        name: "Convivencia Universitaria - Seminarios de Convivencia",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/investigacion-intervencion-convivencia/Seminarios-Convivencia.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/iic-diagnosticos-convivencia",
        name: "Convivencia Universitaria - Diagnósticos de Convivencia",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/investigacion-intervencion-convivencia/Diagnosticos-Convivencia.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/fec-sensibilizacion",
        name: "Convivencia Universitaria - Sensibilización",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/formacion-en-convivencia/Sensibilizacion.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/fec-talleres",
        name: "Convivencia Universitaria - Talleres",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/formacion-en-convivencia/Talleres.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/fec-ferias",
        name: "Convivencia Universitaria - Ferias",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/formacion-en-convivencia/Ferias.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/fec-asesorias",
        name: "Convivencia Universitaria - Asesorías",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/formacion-en-convivencia/Asesorias.vue"
          ),
      },
      {
        path: "/convivencia-universitaria/fec-intervenciones",
        name: "Convivencia Universitaria - Intervenciones",
        component: () =>
          import(
            "@/views/crafted/pages/intranet/convivencia-universitaria/formacion-en-convivencia/Intervenciones.vue"
          ),
      },
      {
        path: "/docs-general",
        name: "Documentos Generales",
        component: () =>
          import("@/views/crafted/pages/intranet/GestionInstitucional.vue"),
      },
      {
        path: "/formularios",
        name: "Formularios",
        component: () =>
          import("@/views/crafted/pages/intranet/Formularios.vue"),
      },
      {
        path: "/vistas",
        name: "Vistas",
        component: () => import("@/views/crafted/pages/intranet/Vistas.vue"),
      },
      {
        path: "/reportes-udatos",
        name: "Reportes UDatos",
        component: () =>
          import("@/views/crafted/pages/intranet/ReportesUDatos.vue"),
      },
      {
        path: "/red-daes-uch",
        name: "Red Daes UChile",
        component: () =>
          import("@/views/crafted/pages/intranet/RedDaesUch.vue"),
      },
      // {
      //   path: "/beneficios-internos",
      //   name: "Beneficios Internos",
      //   component: () =>
      //     import("@/components/page-layouts/BeneficiosInternos.vue"),
      //   children: [
      //     {
      //       path: "/arancel",
      //       name: "Beneficios Internos - Arancel",
      //       component: () => import("@/views/crafted/pages/intranet/Buch.vue"),
      //       props: true,
      //     },
      //     {
      //       path: "projects",
      //       name: "profile-projects",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Projects.vue"),
      //     },
      //     {
      //       path: "campaigns",
      //       name: "Beneficios Internos - Campañas",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Campaigns.vue"),
      //     },
      //     {
      //       path: "documents",
      //       name: "profile-documents",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Documents.vue"),
      //     },
      //     {
      //       path: "connections",
      //       name: "profile-connections",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Connections.vue"),
      //     },
      //     {
      //       path: "activity",
      //       name: "profile-activity",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Activity.vue"),
      //     },
      //   ],
      // },
      {
        path: "/admin",
        name: "Administración",
        component: () => import("@/components/page-layouts/Admin.vue"),
        children: [
          {
            path: "/admin/news/create",
            name: "Administrar Noticias - Crear Noticia",
            component: () =>
              import(
                "@/views/crafted/pages/intranet/admin/news/NewsCreate.vue"
              ),
            props: true,
          },
          {
            path: "/admin/news/edit/:id",
            name: "Administrar Noticias - Editar Noticia",
            component: () =>
              import("@/views/crafted/pages/intranet/admin/news/NewsEdit.vue"),
          },
          {
            path: "/admin/news",
            name: "Administrar Noticias - Listado de Noticias",
            component: () =>
              import("@/views/crafted/pages/intranet/admin/news/NewsList.vue"),
          },
          {
            path: "/admin/posts/create",
            name: "Administrar Documentos - Crear Documento",
            component: () =>
              import(
                "@/views/crafted/pages/intranet/admin/posts/PostsCreate.vue"
              ),
            props: true,
          },
          {
            path: "/admin/posts/edit/:id",
            name: "Administrar Documentos - Editar Documento",
            component: () =>
              import(
                "@/views/crafted/pages/intranet/admin/posts/PostsEdit.vue"
              ),
          },
          {
            path: "/admin/posts",
            name: "Administrar Documentos - Listado de Documentos",
            component: () =>
              import(
                "@/views/crafted/pages/intranet/admin/posts/PostsList.vue"
              ),
          },
        ],
      },
      // {
      //   path: "/crafted/pages/profile",
      //   name: "profile",
      //   component: () => import("@/components/page-layouts/Profile.vue"),
      //   children: [
      //     {
      //       path: "overview",
      //       name: "profile-overview",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Overview.vue"),
      //     },
      //     {
      //       path: "projects",
      //       name: "profile-projects",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Projects.vue"),
      //     },
      //     {
      //       path: "campaigns",
      //       name: "profile-campaigns",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Campaigns.vue"),
      //     },
      //     {
      //       path: "documents",
      //       name: "profile-documents",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Documents.vue"),
      //     },
      //     {
      //       path: "connections",
      //       name: "profile-connections",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Connections.vue"),
      //     },
      //     {
      //       path: "activity",
      //       name: "profile-activity",
      //       component: () =>
      //         import("@/views/crafted/pages/profile/Activity.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/crafted/pages/wizards/horizontal",
      //   name: "horizontal-wizard",
      //   component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      // },
      // {
      //   path: "/crafted/pages/wizards/vertical",
      //   name: "vertical-wizard",
      //   component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      // },
      // {
      //   path: "/crafted/account",
      //   name: "account",
      //   component: () => import("@/views/crafted/account/Account.vue"),
      //   children: [
      //     {
      //       path: "overview",
      //       name: "account-overview",
      //       component: () => import("@/views/crafted/account/Overview.vue"),
      //     },
      //     {
      //       path: "settings",
      //       name: "account-settings",
      //       component: () => import("@/views/crafted/account/Settings.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "/apps/customers/getting-started",
      //   name: "apps-customers-getting-started",
      //   component: () => import("@/views/apps/customers/GettingStarted.vue"),
      // },
      // {
      //   path: "/apps/customers/customers-listing",
      //   name: "apps-customers-listing",
      //   component: () => import("@/views/apps/customers/CustomersListing.vue"),
      // },
      // {
      //   path: "/apps/customers/customer-details",
      //   name: "apps-customers-details",
      //   component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      // },
      // {
      //   path: "/apps/subscriptions/getting-started",
      //   name: "apps-subscriptions-getting-started",
      //   component: () =>
      //     import("@/views/apps/subscriptions/GettingStarted.vue"),
      // },
      // {
      //   path: "/apps/subscriptions/subscription-list",
      //   name: "apps-subscriptions-subscription-list",
      //   component: () =>
      //     import("@/views/apps/subscriptions/SubscriptionList.vue"),
      // },
      // {
      //   path: "/apps/subscriptions/add-subscription",
      //   name: "apps-subscriptions-add-subscription",
      //   component: () =>
      //     import("@/views/apps/subscriptions/AddSubscription.vue"),
      // },
      // {
      //   path: "/apps/subscriptions/view-subscription",
      //   name: "apps-subscriptions-view-subscription",
      //   component: () =>
      //     import("@/views/apps/subscriptions/ViewSubscription.vue"),
      // },
      // {
      //   path: "/apps/calendar",
      //   name: "apps-calendar",
      //   component: () => import("@/views/apps/Calendar.vue"),
      // },
      // {
      //   path: "/apps/chat/private-chat",
      //   name: "apps-private-chat",
      //   component: () => import("@/views/apps/chat/Chat.vue"),
      // },
      // {
      //   path: "/apps/chat/group-chat",
      //   name: "apps-group-chat",
      //   component: () => import("@/views/apps/chat/Chat.vue"),
      // },
      // {
      //   path: "/apps/chat/drawer-chat",
      //   name: "apps-drawer-chat",
      //   component: () => import("@/views/apps/chat/DrawerChat.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/invite-friends",
      //   name: "modals-general-invite-friends",
      //   component: () =>
      //     import("@/views/crafted/modals/general/InviteFriends.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/view-user",
      //   name: "modals-general-view-user",
      //   component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/upgrade-plan",
      //   name: "modals-general-upgrade-plan",
      //   component: () =>
      //     import("@/views/crafted/modals/general/UpgradePlan.vue"),
      // },
      // {
      //   path: "/crafted/modals/general/share-and-earn",
      //   name: "modals-general-share-and-earn",
      //   component: () =>
      //     import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-target",
      //   name: "modals-forms-new-target",
      //   component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-card",
      //   name: "modals-forms-new-card",
      //   component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/new-address",
      //   name: "modals-forms-new-address",
      //   component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      // },
      // {
      //   path: "/crafted/modals/forms/create-api-key",
      //   name: "modals-forms-create-api-key",
      //   component: () =>
      //     import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/two-factor-auth",
      //   name: "modals-wizards-two-factor-auth",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-app",
      //   name: "modals-wizards-create-app",
      //   component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      // },
      // {
      //   path: "/crafted/modals/wizards/create-account",
      //   name: "modals-wizards-create-account",
      //   component: () =>
      //     import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      // },
      // {
      //   path: "/crafted/widgets/lists",
      //   name: "widgets-list",
      //   component: () => import("@/views/crafted/widgets/Lists.vue"),
      // },
      // {
      //   path: "/crafted/widgets/statistics",
      //   name: "widgets-statistics",
      //   component: () => import("@/views/crafted/widgets/Statistics.vue"),
      // },
      // {
      //   path: "/crafted/widgets/charts",
      //   name: "widgets-charts",
      //   component: () => import("@/views/crafted/widgets/Charts.vue"),
      // },
      // {
      //   path: "/crafted/widgets/mixed",
      //   name: "widgets-mixed",
      //   component: () => import("@/views/crafted/widgets/Mixed.vue"),
      // },
      // {
      //   path: "/crafted/widgets/tables",
      //   name: "widgets-tables",
      //   component: () => import("@/views/crafted/widgets/Tables.vue"),
      // },
      // {
      //   path: "/crafted/widgets/feeds",
      //   name: "widgets-feeds",
      //   component: () => import("@/views/crafted/widgets/Feeds.vue"),
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "Iniciar Sesión",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignInView.vue"),
      },
      // {
      //   path: "/sign-up",
      //   name: "sign-up",
      //   component: () =>
      //     import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      // },
      // {
      //   path: "/password-reset",
      //   name: "password-reset",
      //   component: () =>
      //     import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      // },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  //store.commit(Mutations.RESET_LAYOUT_CONFIG);
  document.title = `Gestión DIRBDE - ${String(to.name)}`;
  next();
  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
